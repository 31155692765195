import React, {
  useState, useEffect, useRef, useContext, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { v4 } from 'uuid'

import _ from 'lodash'
import { store } from '../../store'
import { SEND_MESSAGE } from '../../store/reducerActions/messages/send'
import Prompts from './Prompts'
import SendButton from './SendButton'
import { Input, InputWrapper } from './styled/Input'
import { Break } from './styled/Break'
import MessageThread from './MessageThread'
import { CHATBOT_PROPS, PROMPT_STATUS, UI_THREAD_TYPES } from '../../constants'
import { SELECT_PROMPT } from '../../store/reducerActions/prompts'
import { AppContext } from '../../contexts/AppContext'
import AdminToolbar from './AdminToolbar'
import Loading from './Loading'

const Container = styled.div`
  // Not sure why tf this calc has to happen but otherwise you can't see the border-radius
  /* height: calc(100% - 30px); */
  // It looks like the above *seemingly* only applies when running this on a blank page and not when running on live websites
  /* height: 100%; */
  width: 360px;
  position: fixed;
  left: 0;
  bottom: 0;
  border-radius: 0 20px 20px 0;
  padding: 16px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: saturate(180%) blur(30px);

  background-color: ${({ theme }) => theme.backgroundColor};
  ${({ $collapsed }) => {
    if ($collapsed) {
      return {
        maxHeight: '80%',
      }
    }
    return {
      height: '80%',
    }
  }};
  ${({ theme }) => {
    if (theme.border) {
      return {
        border: theme.border,
      }
    }
    return {}
  }};
`
const ThreadContainer = styled.div`
  overflow-y: scroll;
`
const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 16px;
  width: calc(100% - 32px);
  align-items: center;
`
const CloseButton = styled.button`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${({ theme }) => theme.textColor};
  ${({ theme }) => {
    if (theme.isDark) {
      return { backgroundColor: 'black' }
    }
    return { backgroundColor: 'white' }
  }};
`

function Panel(props) {
  const { isDemo } = useContext(AppContext)
  const theme = useTheme()
  // TODO: pass this somewhere else?
  const { associateId } = props
  const [globalState, dispatch] = store()
  const { uiThread, fetchingPrompts, sendingMessage } = globalState
  const [currentInput, setCurrentInput] = useState('')
  const [firstRenderScroll, setFirstRenderScroll] = useState(false)
  const [collapsed, setCollapsed] = useState(localStorage.getItem('COLLAPSED') || !!theme.alwaysCollapsed)

  const bottomOfThreadRef = useRef(null)

  const configureAndSendMessage = ({ message }) => {
    const messageObj = {
      id: v4(),
      message,
      sentTime: '',
      sender: CHATBOT_PROPS.user,
      direction: CHATBOT_PROPS.direction.outgoing,
    }
    dispatch({
      type: SEND_MESSAGE,
      payload: {
        message: messageObj,
        associateId,
      },
    })
  }

  useEffect(() => {
    let options = {}
    if (!firstRenderScroll) {
      options = { behavior: 'smooth', block: 'nearest' }
      setFirstRenderScroll(true)
    }
    bottomOfThreadRef.current.scrollIntoView(options)
  }, [uiThread, firstRenderScroll])

  const onChangeInput = (e) => {
    const { value } = e.target
    setCurrentInput(value)
  }

  const onSendCurrentInput = () => {
    configureAndSendMessage({ message: currentInput })
    setCurrentInput('')
  }

  const onSelectPrompt = (prompt) => {
    dispatch({
      type: SELECT_PROMPT,
      payload: prompt,
    })
    configureAndSendMessage({ message: prompt.body })
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      onSendCurrentInput()
    }
  }

  const onClickCollapse = () => {
    const newStatus = !collapsed
    setCollapsed(newStatus)
    localStorage.setItem('COLLAPSED', newStatus)
  }

  const threadToShow = useMemo(() => {
    if (sendingMessage) {
      return [
        ...uiThread,
        {
          type: UI_THREAD_TYPES.sendingMessage,
          id: v4(),
        },
      ]
    }
    if (fetchingPrompts) {
      return [
        ...uiThread,
        {
          type: UI_THREAD_TYPES.fetchingPrompts,
          id: v4(),
        },
      ]
    }
    return uiThread
  }, [uiThread, sendingMessage, fetchingPrompts])

  // console.log('thread ', _.cloneDeep(uiThread.map((thr) => ({ type: thr.type, status: thr.status }))))
  return (
    <Container $collapsed={collapsed}>
      <Toolbar>
        { !theme.alwaysCollapsed
        && <CloseButton onClick={onClickCollapse}>{ collapsed ? '^' : 'X'}</CloseButton>}
        {isDemo ? <AdminToolbar /> : null}
      </Toolbar>
      <ThreadContainer>
        {threadToShow.map((thread) => {
          switch (thread.type) {
            case UI_THREAD_TYPES.prompts:
              if (thread.status === PROMPT_STATUS.hidden) return null
              return (
                <Prompts
                  key={thread.id}
                  prompts={thread.data}
                  onSelectPrompt={onSelectPrompt}
                />
              )
            case UI_THREAD_TYPES.messages:
            case UI_THREAD_TYPES.error:
              return (
                <MessageThread key={thread.id} messages={thread.data} />
              )
            case UI_THREAD_TYPES.fetchingPrompts:
            case UI_THREAD_TYPES.sendingMessage:
              return <Loading key={thread.id} type={thread.type} />
            default:
              return null
          }
        })}
        <div ref={bottomOfThreadRef} />
      </ThreadContainer>
      <Break />
      <InputWrapper $isPanel>
        <Input placeholder="Ask me anything" value={currentInput} onChange={onChangeInput} onKeyDown={onKeyDown} />
        <SendButton isActive={!!currentInput} onClick={onSendCurrentInput} />
      </InputWrapper>
    </Container>
  )
}

Panel.propTypes = {
  associateId: PropTypes.string.isRequired,
}

export default Panel
