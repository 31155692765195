import React from 'react'
import styled from 'styled-components'
import { store } from '../../store'
import { CLEAR_UI_THREAD } from '../../store/reducerActions/globalState'

const Container = styled.div`
  height: 20px;
  /* position: absolute; */
  /* top: 30px; */
  /* left: 10px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`
const Button = styled.button`
  padding: 5px;
  border: none;
  font-size: 12px;
  font-weight: 300;
  border-radius: 5px;
  line-height: 1;
  color: ${({ theme }) => theme.textColor}
  background-color: ${({ theme }) => theme.buttonBackgroundColor || 'black'}};
`

function AdminToolbar() {
  const [, dispatch] = store()
  const clearHistory = () => {
    localStorage.removeItem('CACHED_STATE')
    dispatch({ type: CLEAR_UI_THREAD })
  }

  return (
    <Container>
      <Button onClick={clearHistory}>Clear</Button>
    </Container>
  )
}

export default AdminToolbar
