import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { Comment, ThreeCircles } from 'react-loader-spinner'

import { UI_THREAD_TYPES } from '../../constants'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  align-items: center;
`
const Text = styled.p`
  margin-left: 10px;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.65);
`

function Loading({ type }) {
  const theme = useTheme()
  switch (type) {
    case UI_THREAD_TYPES.fetchingPrompts:
      return (
        <Container $center>
          <ThreeCircles
            height="40"
            width="40"
            color={theme.focusColor}
          />
          <Text>Generating prompts</Text>
        </Container>
      )
    case UI_THREAD_TYPES.sendingMessage:
      return (
        <Container>
          <Comment
            height="40"
            width="40"
            backgroundColor={theme.focusColor}
          />
          <Text>{'Men\'s Health is typing'}</Text>
        </Container>
      )
    default:
      return (
        <div>Loading</div>
      )
  }
}

Loading.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Loading
