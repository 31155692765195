import styled from 'styled-components'

const InputWrapper = styled.div`
  display: flex;

  padding: 10px;

  background-color: ${({ theme }) => theme.inputBackgroundColor};

  ${({ $isPanel, theme }) => {
    if ($isPanel) {
      return {
        'flex-direction': 'row',
      }
    }
    return {
      flex: 1,
      'min-height': '80px',
      border: `1px solid ${theme.inputBorderColor}`,
      'border-radius': '12px',
      'flex-direction': 'column',
    }
  }}
`
const Input = styled.textarea`
  flex: 2;
  border: none;
  resize: none;
  font-weight: 400;
  font-size: 16px;

  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.inputBackgroundColor};

  &:focus,
  &:focus-visible {
    outline: none;
  }
`

export {
  InputWrapper,
  Input,
}
