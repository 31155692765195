import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from './styled/Button'
import PromptsLoading from './PromptsLoading'
import { ORIENTATION, PROMPT_STATUS } from '../../constants'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;
  gap: 8px;
  margin-top: 10px;
  ${({ orientation }) => {
    if (orientation === ORIENTATION.panel) {
      return {
        'flex-direction': 'column',
      }
    }
    return {
      flex: 1,
    }
  }}
`
const Prompt = styled(Button)`
  margin-top: 1px;
  margin-bottom: 1px;
`

function Prompts(props) {
  const {
    prompts,
    onSelectPrompt,
    orientation,
  } = props

  let body
  if (prompts.length === 0) {
    body = null
  } else {
    body = prompts.map((prompt) => {
      if (prompt.status === PROMPT_STATUS.hidden) return null
      return (
        <Prompt
          key={prompt.id}
          onClick={() => onSelectPrompt(prompt)}
          $fullWidth={orientation === ORIENTATION.panel}
          $active={prompt.selected}
          disabled={prompt.selected}
        >
          {prompt.body}
        </Prompt>
      )
    })
  }
  return (
    <Container orientation={orientation}>
      {body}
    </Container>
  )
}

Prompts.propTypes = {
  prompts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectPrompt: PropTypes.func.isRequired,
  orientation: PropTypes.string,
}

Prompts.defaultProps = {
  orientation: ORIENTATION.panel,
}

export default Prompts
