import React from 'react'
import styled from 'styled-components'

import logo from '../assets/logo.svg'

const Nav = styled.nav`
  height: 48px;
  background-color: #d2232e;
  color: #fff;
  position: fixed;
  width: 100%;
`
const NavInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  max-width: 75rem;
  width: 100%;
`
const Logo = styled.img`
  width: 6.5rem;
  height: 1.5rem;
  margin-right: 0.3125rem;
`
const MenuLeft = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`
const MenuItem = styled.p`
  text-transform: uppercase;
  padding: 0 1.25rem 0 1.25rem;
  font-size: 0.8125rem;
  white-space: nowrap;
  font-family: GraphikBold,GraphikBold-upcase-roboto,GraphikBold-upcase-local,Helvetica,Arial,Sans-serif;
  line-height: 2.58918;
`

function Navbar() {
  return (
    <Nav>
      <NavInner>
        <Logo src={logo} alt="Men's Health" />
        <MenuLeft>
          <MenuItem>Fitness</MenuItem>
          <MenuItem>Health</MenuItem>
          <MenuItem>Gear</MenuItem>
          <MenuItem>Style</MenuItem>
          <MenuItem>Grooming</MenuItem>
        </MenuLeft>
      </NavInner>
    </Nav>
  )
}

export default Navbar

/* eslint-disable */
const copiedNav = (
  <nav data-tracking-id="topNav" className="css-w4tun2 efnztv76">
    <div className="nav-bar-container css-5464hb efnztv75">
      <button aria-label="Sidepanel Button" className="css-1u0dgx1 e12nq69g1">
        <svg alt="Menu" title="Side Panel" data-dynamic-svg="true">
          <use href="/_assets/design-tokens/fre/static/icons/menu.cf1782e.svg?embed#icon" />
        </svg>
      </button>
      <a href="/" aria-label="Men's Health" className="nav-logo css-c8gw08 e1c1bym14">
        <img data-dynamic-svg="true" src="/_assets/design-tokens/menshealth/static/images/logos/logo.f037c7b.svg?primary=%2523ffffff" fetchpriority="high" data-testid="dynamic-svg-base" height="auto" width="auto" alt="Logo" className="css-0 e1ccpyf90" />
      </a>
      <div style="scrollbar-width:none;" className="css-1p3bm3c efnztv72">
        <ul className="css-xs3857 efnztv71">
          <li className="css-wby4sm efnztv70">
            <a href="/fitness/" aria-current="false" className="e1c1bym11 css-1b9n4xa e1c1bym14">Fitness</a>
          </li>
          <li className="css-wby4sm efnztv70">
            <a href="/health/" aria-current="false" className="e1c1bym11 css-1b9n4xa e1c1bym14">Health</a>
          </li>
          <li className="css-wby4sm efnztv70"><a href="/technology-gear/" aria-current="false" className="e1c1bym11 css-1b9n4xa e1c1bym14">Gear</a></li>
          <li className="css-wby4sm efnztv70"><a href="/style/" aria-current="false" className="e1c1bym11 css-1b9n4xa e1c1bym14">Style</a></li>
          <li className="css-wby4sm efnztv70"><a href="/grooming/" aria-current="false" className="e1c1bym11 css-1b9n4xa e1c1bym14">Grooming</a></li>
        </ul>
      </div>
      <a href="https://membership.menshealth.com/mens-health-mvp-premium-2.html?cds_tracking_code=mhltopnav" className="css-1asiud9 e1c1bym14">Subscribe</a>
      <ul className="css-1em4i88 ea8vq921">
        <li aria-controls="accountmenu" aria-label="account" className="css-79elbk ea8vq923">
          <a href="#" role="button" title="account" aria-expanded="false" aria-labelledby="accountmenu" className="css-1nkufuv e1c1bym14">
            <span data-account="Account" data-login="Log In" className="account-text css-1dup2df e7dmux21">account</span>
          </a>
          <ul data-scale="0" id="accountmenu" role="menu" className="css-8sf55n ea8vq922">
            <li className="css-uh74tq ea8vq920"><a rel="nofollow" href="/auth/csrf?action=account&amp;return_url=https://www.menshealth.com/nutrition/a28493677/what-is-cico-diet/" title="my account" className="css-80h0jn e1c1bym14">my account</a></li>
            <li className="css-uh74tq ea8vq920"><a rel="nofollow" href="/my-stuff" title="My Bookmarks" className="css-80h0jn e1c1bym14">My Bookmarks</a></li>
            <li className="css-uh74tq ea8vq920"><a rel="nofollow" href="/auth/csrf?action=logout&amp;return_url=https://www.menshealth.com/nutrition/a28493677/what-is-cico-diet/" title="sign out" className="css-80h0jn e1c1bym14">sign out</a></li>
          </ul>
        </li>
      </ul>
      <button role="button" aria-label="search" aria-expanded="false" className="ekaubry4 css-3t22as e12nq69g1">
        <img data-dynamic-svg="true" src="/_assets/design-tokens/fre/static/icons/search.f1c199c.svg?primary=%2523ffffff" loading="lazy" data-testid="dynamic-svg-base" height="16" width="16" alt="Search" className="css-6dr9m8 ekaubry3" />
      </button>
    </div>
  </nav>
)
