import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'

import Arrow from '../../assets/ArrowCurveUp@3x.png'
import Arrow_Light from '../../assets/ArrowCurveUp_Light@3x.png'
import { AppContext } from '../../contexts/AppContext'

const Container = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.buttonBackgroundColor || 'black'};
  ${({ $isActive, theme }) => {
    let toReturn = {}
    if ($isActive) {
      toReturn = {
        boxShadow: `0px 2px 20px -3px ${theme.focusColor || 'rgb(234, 166, 14)'}`,
      }
    }
    return toReturn
  }};
`
const Img = styled.img`
  height: 12px;
`

function SendButton({ isActive, onClick }) {
  const { isDark } = useContext(AppContext)
  return (
    <Container $isActive={isActive} onClick={onClick}>
      <Img src={isDark ? Arrow : Arrow_Light} alt="Send" />
    </Container>
  )
}

SendButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

SendButton.defaultProps = {
  isActive: false,
}

export default SendButton
