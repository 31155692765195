import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../contexts/AppContext'

const Container = styled.div`
  margin-top: 1.875rem;
  border-top: thin solid #595959;
`
const AuthorGroup = styled.div`
  display: grid;
  grid-template-areas:
    "authorImage nameJobTitle"
    "authorImage bioContainer";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-column-gap: 0.625rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  clear: both;
`
const Image = styled.img`
  grid-area: authorImage;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  object-fit: cover;
`
const TitleGroup = styled.div`
  grid-area: nameJobTitle;
  text-align: left;
`
const Name = styled.a`
  font-size: 0.70028rem;
  line-height: 1.2;
  color: #6f6f6f;
  font-family: GraphikBold;
  text-decoration: underline;
  text-decoration-color: inherit;
  text-decoration-thickness: 0.0625rem;
  text-transform: uppercase;
  text-underline-offset: 0.25rem;
  text-align: left;
  &:hover {
    cursor: pointer;
  }
`
const Title = styled.div`
  font-size: 0.70028rem;
  line-height: 1.2;
  color: #6F6F6F;
  font-family: GraphikBold,GraphikBold-upcase-roboto,GraphikBold-upcase-local,Helvetica,Arial,Sans-serif;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-top: 0.625rem;
  text-transform: uppercase;
  text-align: left;
`
const BioContainer = styled.div`
  grid-area: bioContainer;
  font-family: Charter;
  font-size: 1rem;
  line-height: 1.4;
  overflow: hidden;
  padding-top: 0.3125rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  word-break: break-word;
  text-align: left;
`

function Authors() {
  const { onClickLink } = useContext(AppContext)
  return (
    <Container>
      <AuthorGroup>
        <Image alt="Melissa Matthews" src="https://hips.hearstapps.com/rover/profile_photos/d2bfbcda-e4fe-4809-aa18-0d46891eb801_1534799285.jpg?fill=1:1&resize=120:*" />
        <TitleGroup>
          <Name onClick={onClickLink}>Melissa Matthews</Name>
          <Title>Health Writer</Title>
        </TitleGroup>
        <BioContainer>{ `Melissa Matthews is the Health Writer at Men's Health, covering the latest in food, nutrition, and health. `}</BioContainer>
      </AuthorGroup>
      <AuthorGroup>
        <Image alt="Paul Kita" src="https://hips.hearstapps.com/rover/profile_photos/2bd137b4-0718-4a9b-b4c7-678ef9826e0e_1545244881.jpg?fill=1:1&resize=120:*" />
        <TitleGroup>
          <Name onClick={onClickLink}>Paul Kita</Name>
        </TitleGroup>
        <BioContainer>
          { `Paul is the Food & Nutrition Editor of Men's Health. He's also the author of two cookbooks: `}
          <em>Guy Gourmet</em>
          {' '}
          and
          <em>A Man, A Pan, A Plan</em>
          .
        </BioContainer>
      </AuthorGroup>
    </Container>
  )
}

export default Authors
