import React, { useContext } from 'react'
import styled from 'styled-components'
import Authors from './Authors'
import { AppContext } from '../contexts/AppContext'

const Container = styled.div`
  max-width: calc(20.625rem * 2 - 1rem);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Paragraph = styled.p`
  font-family: 'Charter';
  font-size: 1.1875rem;
  line-height: 1.6;
  text-align: left;
`
const SectionHeader = styled.h2`
  font-size: 3.125rem;
  line-height: 1;
  letter-spacing: 0.06rem;
  font-family: GraphikBold,Knockout-roboto,Knockout-local,Helvetica,Arial,Sans-serif;
  font-weight: normal;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  width: 100%;
  text-align: left;
`
const Strong = styled.strong`
  font-weight: bold;
`
const StyledLink = styled.a`
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
  text-decoration-color: #d2232e;
  text-underline-offset: 0.25rem;
  transition: all 0.3s ease-in-out;
  word-break: break-word;
  color: inherit;
  &:hover {
    cursor: pointer;
  }
`
const Em = styled.em``

function Link({ children }) {
  const { onClickLink } = useContext(AppContext)
  return <StyledLink onClick={onClickLink}>{children}</StyledLink>
}

function Content() {
  const onClickLink = (e) => {
    e.preventDefault()
    console.log('CLICK')
  }
  /* eslint-disable */
  return (
    <Container>
      <Img src="https://hips.hearstapps.com/hmg-prod/images/hlh030122bodycicodiet-002-1645710587.png?crop=1.00xw:0.758xh;0,0.0745xh&resize=640:*" alt="Food Scale" />
      <figcaption>
        Alex Lau
      </figcaption>
      {/* eslint-disable max-len */}
      <Paragraph><Strong>THE CICO DIET</Strong> {`isn't exactly new, even if this is the first time you\'re reading about it. And even "new" supposedly innovative diets aren\'t often based off of breaking research (yes, even `} <Link onClick={onClickLink}>Keto</Link>{`).` }</Paragraph>
      <Paragraph>{ `You see, the latest weight-loss fads only appear different. Diet marketers (yes, they exist) try to create this separation with their particular diet\'s set of rules, banned foods or food groups, and/or daily caps on certain nutrients.` }</Paragraph>
      <Paragraph>{`But the truth is that most diets operate upon roughly the same mechanism: cutting calories. Whether it’s `} <Link>Whole30</Link>, <Link>Paleo</Link>, { `or Keto, the goal of these diet plans is to encourage (demand that?) you to eat less food overall, thereby losing weight.` }</Paragraph>
      <Paragraph>{`This approach works—but as we\'ve written about countless times before—often only in the short term. And "failing" a diet can lead to re-gaining weight, a process known as "yo-yo dieting." In short, `}<Link>fad diets don't work</Link>{` long term.` }</Paragraph>
      <Paragraph>{ `And so now here comes an emerging diet trend called “Calories In, Calories Out” (CICO), which is puts the calorie-counting method of weight loss front and center.` }</Paragraph>
      <Paragraph>{ `The CICO plan operates under the premise that you\'ll lose weight by consuming fewer calories than your body uses to perform its daily functions.` }</Paragraph>
      <Paragraph>{ `There’s no way this popular diet is as simple as counting calories, right?` }</Paragraph>
      <Paragraph>{`"The idea of calories in and calories out is absolutely the backbone of weight loss," says `}<Link>Bethany Doerfler</Link>{`, M.S., R.D.N., Clinical Dietitian at Northwestern University. "But metabolism and weight loss are so much more complex than that."` }</Paragraph>
      <Paragraph>{ `So before you jump on the CICO bandwagon, it’s important to understand a few key elements of how your metabolism and your body works when it comes to dieting and weight loss.` }</Paragraph>
      <Paragraph>{ `There’s a healthy way to do CICO and there’s a way that can create some serious issues. Here’s what you should consider when it comes to health, happiness, and satisfaction, before you adopt the CICO approach to losing weight.` }</Paragraph>
      <SectionHeader>What Is the CICO Diet?</SectionHeader>
      <Paragraph>{`"There’s no real plan with CICO," says Dezi Abeyta, R.D.N., a Men\'s Health nutrition advisor and author of `}<Link><Em>The Lose Your Gut Guide</Em></Link>.</Paragraph>
      <Paragraph>{ `And that\'s actually not a bad thing.` }</Paragraph>
      <Paragraph>{ `On CICO you just consume fewer calories (calories in, or “CI”) than you burn (calories out, or “CO”) every day. And, honestly, any diet—be it keto, paleo, Whole30, or otherwise—can be a complicated method for consuming fewer calories than you burn. CICO attempts to simplify everything.` }</Paragraph>
      <Img
        srcSet='https://hips.hearstapps.com/hmg-prod/images/grilled-chicken-and-rice-salad-bowl-royalty-free-image-1043282118-1557166966.jpg?resize=640:* 640w, https://hips.hearstapps.com/hmg-prod/images/grilled-chicken-and-rice-salad-bowl-royalty-free-image-1043282118-1557166966.jpg?resize=768:* 980w, https://hips.hearstapps.com/hmg-prod/images/grilled-chicken-and-rice-salad-bowl-royalty-free-image-1043282118-1557166966.jpg?resize=980:* 1120w, https://hips.hearstapps.com/hmg-prod/images/grilled-chicken-and-rice-salad-bowl-royalty-free-image-1043282118-1557166966.jpg?resize=980:* 1200w, https://hips.hearstapps.com/hmg-prod/images/grilled-chicken-and-rice-salad-bowl-royalty-free-image-1043282118-1557166966.jpg?resize=980:* 1920w'
        alt="Grilled chicken and rice salad bowl"
      />
      <Paragraph>{ `That said, let\'s complicate things just a bit so that you can understand the mechanism by which CICO argues that it works.` }</Paragraph>
      <Paragraph><Link>Basal metabolic rate (BMR)</Link>{` is the amount of calories your body burns to stay alive. This figure is individual specific and determined by a number of factors including height, weight, and age. Online calculators offer an estimate, and some doctors `}<Link>use breathing machines</Link>{` that deliver more precise and customized BMRs.` }</Paragraph>
      <Paragraph>{`Then lifestyle factors like physical activity need to be accounted for since you\'re probably not lying in bed all day. Again, `}<Link>online calculators</Link> and <Link>formulas offer rough</Link>{` estimates of how many calories your body uses.` }</Paragraph>
      <Paragraph>{ `Losing weight requires eating fewer calories than your body needs to maintain its activity level. Here\'s where CICO comes in.` }</Paragraph>
      <Paragraph>{ `On the CICO diet you track how many calories you consume daily by way of the aforementioned calculators and then you try to eat fewer calories a day.` }</Paragraph>
      <Paragraph>{ `So, let\'s say that you figure out your calorie need for the day is 2,600 calories. You could then aim to eat 2,200 calories daily in an attempt to lose weight.` }</Paragraph>
      <Paragraph>{ `Run a calorie deficit and you’ll start to drop pounds.` }</Paragraph>
      <Paragraph>{ `And, really, there are no off-limit foods as long as you stay under that calorie threshold for the day.` }</Paragraph>
      <Paragraph>{ `"Everything is on the table," says Abeyta. "So if you wanted to drink beer and eat wings every day, you could—as long as you either consumed less of those things or exercised more to maintain a calorie deficit." Is that a good way to approach CICO in terms of building long-term health? Absolutely not.` }</Paragraph>
      <Paragraph>{ `So the premise of CICO is rooted in simplicity, but the actual execution of the CICO diet can be challenging. Calorie counting requires meticulous tracking, which can be especially hard to do when dining out or if you\'re traveling.` }</Paragraph>
      <Paragraph>{ `Plus, math.` }</Paragraph>
      <Paragraph>{ `But people have seen results on the diet.` }</Paragraph>
      <SectionHeader>Does the CICO Diet Work?</SectionHeader>
      <Paragraph>{ `It depends what you mean by "work."` }</Paragraph>
      <Paragraph>{`It\'s generally accepted that most diets work because of a calorie deficit, says `}<Link>Abby Langer</Link>{`, R.D. So if we\'re talking about the CICO diet and short-term weight loss, sure, it can work.` }</Paragraph>
      <Paragraph>{ `""However, calories as we know them are so arbitrary," Langer says. "The calorie was invented a really long time ago and isn’t necessarily accurate in terms of how our bodies metabolize each individual food. We’re learning so much more about how calories are absorbed."` }</Paragraph>
      <Paragraph>{ `Your body takes in more calories from food that\'s been processed, or broken down from its natural form, she explains. "So if you have a smoothie you’re going to absorb a significant portion of those calories compared to if you eat that food raw or cooked," says Langer.` }</Paragraph>
      <Paragraph>{ `Emerging research shows your body handles ultra-processed items like chips or Twinkies differently than kale or bananas. "Those [packaged snacks] are going to be viewed very differently on a cellular level," says Doerfler.` }</Paragraph>
      <Paragraph>{`The National Institute of Health found that ghrelin, a hormone that drives hunger, is higher when people consume ultra-processed foods compared to after eating a well-balanced diet, according to 2019 research published in `}<Link><Em>Cell Metabolism</Em></Link>.</Paragraph>
      <Paragraph>{ `Heavily-processed foods high in sugar, fat, and salt, may spike insulin levels and cause your body to store more fat, explains Doerfler.` }</Paragraph>
      <Paragraph>{ `Then, you have to consider the benefits of a fiber.` }</Paragraph>
      <Paragraph>{ `"Fiber-rich foods provide a certain level of satiety and fullness," says Doerfler. The nutrient also promotes healthy gut bacteria, which scientists believe may determine a person\'s weight.` }</Paragraph>
      <Paragraph>{ `"By laser-focusing on calories, you may forget about filling fiber, muscle-building protein, and disease-fighting micronutrients," says Abeyta.` }</Paragraph>
      <Paragraph>{ `It’s great to know how many calories you eat daily, in general. "If you’re self-motivated and know that a good diet includes lean proteins, colorful produce, quality fats, and fiber-rich carbs, well, then CICO can help you lose weight and improve your health," Abeyta says.` }</Paragraph>
      <Paragraph>{ `But if your diet is still rich in junk food—even if you\'re eating less of it and you\'re losing weight—you\'re not not eating a diet that sets you up for long-term health.` }</Paragraph>
      <SectionHeader>Is the CICO Diet Healthy?</SectionHeader>
      <Paragraph>{ `We\'ve talked about this a little already from a physical standpoint, but it\'s also important to talk about diet from a mental perspective.` }</Paragraph>
      <Paragraph>{ `Counting calories isn\'t inherently problematic, says Doerfler. But there are exceptions.` }</Paragraph>
      <Img
        srcSet="https://hips.hearstapps.com/hmg-prod/images/gettyimages-1140100043.jpg?crop=0.668xw:1.00xh;0.333xw,0&resize=640:* 640w, https://hips.hearstapps.com/hmg-prod/images/gettyimages-1140100043.jpg?crop=0.668xw:1.00xh;0.333xw,0&resize=768:* 980w, https://hips.hearstapps.com/hmg-prod/images/gettyimages-1140100043.jpg?crop=0.668xw:1.00xh;0.333xw,0&resize=980:* 1120w, https://hips.hearstapps.com/hmg-prod/images/gettyimages-1140100043.jpg?crop=0.668xw:1.00xh;0.333xw,0&resize=980:* 1200w, https://hips.hearstapps.com/hmg-prod/images/gettyimages-1140100043.jpg?crop=0.668xw:1.00xh;0.333xw,0&resize=980:* 1920w"
        alt="Cropped shot of senior man measuring his waist"
      />
      <Paragraph>{ `"I like that this diet has brought back to the forefront looking at calories and seeing where your high-calorie food comes from," she says. "Almost all the time those calories come in the form of refined snack foods and sugar-sweetened beverages. The hope is that those calories would be used for smarter and healthier eating."` }</Paragraph>
      <Paragraph>{ `Realizing that morning bagel with cream cheese tops more than 600 calories could inspire you to choose a satisfying meal that contains fewer calories, like oatmeal and peanut butter.` }</Paragraph>
      <Paragraph>{`Plus, apps like MyFitnessPal are useful ways to monitor what you\'re eating. People who spent just 15 minutes a day logging food lost about 10 percent of their body weight in one month, according to a February 2019 study `}<Link>published in the journal <Em>Obesity</Em></Link>.</Paragraph>
      <Paragraph>{ `That said, counting calories can feel like a chore, lead to obsessive behavior, and even spark binge eating, says Langer.` }</Paragraph>
      <Paragraph>{ `"When you’re drilling everything down to numbers it takes away all the joy of food and disconnects us from nourishing our bodies," she asserts. "This perpetuates diet culture by being punishing."` }</Paragraph>
      <Paragraph>{ `Stressing over numbers can be a trigger to binge for people with eating disorders. If you have a history of disordered eating, consult a dietitian who can customize a plan that works for you.` }</Paragraph>
      <Paragraph>{ `"If you ever find yourself \'running off\' indulgences or skipping meals for the sake of CICO, that’s a signal flare," says Abeyta.` }</Paragraph>
      <Paragraph>{ `And strict diets don\'t take birthdays—or any other celebratory meal, really—into consideration.` }</Paragraph>
      <Paragraph>{ `"People are not quite so robotic," says Doerfler. "Even if someone needs 1,200 calories a day to lose weight, most people are not adhering to that 100 percent of the time."` }</Paragraph>
      <Paragraph>{ `Ultimately, the best diet is one that doesn\'t consume your every thought. Dieting becomes an issue when you refuse to eat even though you\'re hungry, says Langer.` }</Paragraph>
      <Paragraph>{ `"If you have to pee you wouldn’t say, \'No, I can’t pee for another three hours,\'" she asserts.` }</Paragraph>
      <Paragraph>{ `Langer recommends listening to your body\'s natural hunger cues and taking a more relaxed approach.` }</Paragraph>
      <Paragraph>{ `"Stop thinking of food as good or bad and just eat," she says.` }</Paragraph>
      <Authors />
    </Container>
  )
}

export default Content
