import styled from 'styled-components'

const Button = styled.button`
  padding: 14px 16px;
  line-height: 1;
  height: fit-content;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  width: 100%;

  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.buttonBackground};
  border: ${({ theme, $active }) => `1px solid ${$active ? theme.focusColor : theme.inputBorderColor}`};
  font-family: ${({ theme }) => theme.fontFamily};
  ${({ $fullWidth }) => {
    if ($fullWidth) {
      return {
        width: '100%',
      }
    }
    return {}
  }}
  &:hover {
    ${({ $active, theme }) => {
    if ($active) return {}
    return {
      cursor: 'pointer',
      border: `1px solid ${theme.focusColor}`,
    }
  }}
  }
`

export { Button }
