import React from 'react'
import { ChatbarAndPanel } from '@keysai/chatbot-ui'

import Navbar from './Navbar'
import Body from './Body'

function Layout() {
  console.log('BACKEND URL ', process.env.REACT_APP_HOST_URL)
  return (
    <div>
      <Navbar />
      <Body />
      <ChatbarAndPanel
        isPanel
        associateId="asst_H2GoiPDJ0Eq26jlNnWUh83KV"
        overrideUrl={process.env.REACT_APP_HOST_URL}
        isDark={false}
      />
    </div>
  )
}

export default Layout
