import { createContainer } from 'react-tracked'
import { useReducerAsync } from 'use-reducer-async'
import { v4 } from 'uuid'

import reducerActions from './reducerActions'
import { CHATBOT_PROPS } from '../constants'

const messages = []
// [{
//   id: '1',
//   message: "Hello, what can I help you with? For styling purposes let's make sure this bitch wraps all the way up around",
//   sentTime: 'just now',
//   sender: CHATBOT_PROPS.assistant,
//   direction: CHATBOT_PROPS.direction.incoming,
// }, {
//   id: '2',
//   message: "Hey what's the biggest car available built after 2023? I have a bunch of kids I need to drive around",
//   direction: CHATBOT_PROPS.direction.outgoing,
// }]

const prompts = [
  {
    id: v4(),
    body: "What's the cheapest truck you have?",
  },
  {
    id: v4(),
    body: 'What are the Carfax for this model?',
  },
  {
    id: v4(),
    body: 'What colors do you have?',
  },
  {
    id: v4(),
    body: 'What colors do you have?',
  },
]

const initialState = {
  messages,
  // TODO: separate the old Chatbot store
  messageHistory: [
    ...messages,
  ],
  sendingMessage: false,
  errorSendingMessage: null,
  // OpenAI tracks threads via an id
  threadId: '',
  associateId: '',
  // TODO: These will be dynamic by url
  prompts: [],
  fetchingPrompts: false,
  fetchingPromptsError: '',
  selectedPrompt: {
    id: '',
    body: '',
  },
  // This holds all data in the thread. For now, prompts and messages
  // See ChatbarAndPanel/docs/dataStructures.js
  uiThread: [],
}

function reducer(state, { type, payload }) {
  const action = reducerActions.sync[type]
  if (!action) throw new Error(`Invalid action specified: ${type}`)
  return action(state, { type, payload })
}

const { Provider, useTracked } = createContainer(() => useReducerAsync(reducer, initialState, reducerActions.async))

const store = useTracked
export { Provider, store }
