import React, { useContext } from 'react'
import styled from 'styled-components'

import { AppContext } from '../contexts/AppContext'

const Container = styled.header`
  width: 100%;
  max-width: 100vw;
  margin-bottom: 0.9375rem;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 5rem;
`
const Inner = styled.div`
  margin: auto;
  max-width: calc(20.625rem * 2 - 1rem);
`
const Head = styled.h1`
  font-size: 3.75rem;
  line-height: 1.1;
  font-family: GraphikCondensed;
  font-weight: normal;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  margin-left: 0;
  margin-right: 0;
`
const Subhead = styled.p`
  font-family: GraphikBold;
  color: #595959;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: normal;
`
const Meta = styled.address`
  font-size: 0.70028rem;
  line-height: 1.2;
  color: #6f6f6f;
  font-family: GraphikBold;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding-right: 0.3125rem;
  text-transform: uppercase;
`
const StyledLink = styled.span`
  text-decoration-line: underline;
  text-decoration-style: initial;
  text-decoration-thickness: 0.0625rem;
  text-decoration-color: inherit;
  text-underline-offset: 0.25rem;
  color: inherit;
  transition: all 0.3s ease-in-out 0s;
  word-break: break-word;
  align-items: center;
  cursor: pointer;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-align: left;
  position: relative;
  line-height: 1.6;
  padding: 0rem;
`

function LinkComp({ children }) {
  const { onClickLink } = useContext(AppContext)
  return <StyledLink onClick={onClickLink}>{ children }</StyledLink>
}

function Header() {
  return (
    <Container>
      <Inner>
        <Head>The CICO Diet Can Help You Lose Weight—at a Cost</Head>
        <Subhead>Calories in. Calories out. Easy, right?</Subhead>
        <Meta>
          By
          {' '}
          <LinkComp>Melissa Matthews</LinkComp>
          {' '}
          and
          {' '}
          <LinkComp>Paul Kita</LinkComp>
          . Published: Feb 24, 2022
        </Meta>
      </Inner>
    </Container>
  )
}

export default Header
