import React, { useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { v4 } from 'uuid'

const pulseBorder = keyframes`
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #3498db;
  }
  100% {
    border-color: transparent;
  }
`
const pulseBar = keyframes`
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`

const Container = styled.div`
  position: relative;
  width: 200px;
  height: 100px;
  border: 1px solid #3498db;
  border-radius: 5px;
  overflow: hidden;
`
const PulseBorder = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid transparent;
  border-radius: 5px;
  animation: ${pulseBorder} 2s infinite;
`
const PulseRectangle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: #3498db;
  animation: ${pulseBar} 2s infinite;
`

function PromptsLoading() {
  const ids = useMemo(() => [...Array(4).keys()].map(() => v4()), [])
  const body = ids.map((id) => (
    <React.Fragment key={id}>
      <PulseBorder />
      <PulseRectangle />
    </React.Fragment>
  ))
  return (
    <Container>{body}</Container>
  )
}

export default PromptsLoading
