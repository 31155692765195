import messagesActions from './messages'
import promptActions from './prompts'
import globalStateActions from './globalState'

export default {
  sync: {
    ...messagesActions.sync,
    ...promptActions.sync,
    ...globalStateActions.sync,
  },
  async: {
    ...messagesActions.async,
    ...promptActions.async,
  },
}
