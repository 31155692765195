const SEED_GLOBAL_STATE = 'SEED_GLOBAL_STATE'
const CLEAR_UI_THREAD = 'CLEAR_UI_THREAD'

const globalStateActions = {
  sync: {
    [SEED_GLOBAL_STATE]: (state, { payload }) => {
      return {
        ...payload,
      }
    },
    [CLEAR_UI_THREAD]: (state) => {
      return {
        ...state,
        uiThread: [],
      }
    },
  },
}

export { SEED_GLOBAL_STATE, CLEAR_UI_THREAD }
export default globalStateActions
