function log(...args) {
  console.log('\n\nKEYS ', args, '\n\n')
}
function configureNavigationListener() {
  // log('configureNavigationListener')
  window.addEventListener('hashchange', (a, b) => {
    // log('HASHCHANGE ', a, b)
  })
  window.addEventListener('popstate', (a, b) => {
    // log('POPSTATE ', a, b)
  })
  window.addEventListener('DOMContentLoaded', (a, b) => {
    // log('DOMContentLoaded ', a, b)
  })
}

export { configureNavigationListener, log }
