import { v4 } from 'uuid'
import _ from 'lodash'

import { PROMPT_STATUS, UI_THREAD_TYPES } from '../../../constants'

function getUIThreadCopyWithNewItem({ state, payload, type }) {
  const uiThread = [...state.uiThread]
  const last = _.last(uiThread)
  let newUIThreadSection
  if (last && last.type === type) {
    const lastCopy = { ...uiThread.pop() }
    let lastCopyData = [...lastCopy.data]
    if (type === UI_THREAD_TYPES.messages) {
      // When adding a single user's message we'll have a single message (payload.message)
      // When adding the Bot's messages there is an array of possibly multiple (payload.messages)
      if (payload.message) {
        lastCopyData.push(payload.message)
      } else {
        lastCopyData = lastCopyData.concat(payload.messages)
      }
    }
    lastCopy.data = lastCopyData
    uiThread.push(lastCopy)
  // TODO: condition for adding prompts
  } else {
    let data
    switch (type) {
      case UI_THREAD_TYPES.messages:
        data = payload.message ? [payload.message] : [...payload.messages]
        break
      case UI_THREAD_TYPES.prompts:
        data = payload.prompts
        break
      case UI_THREAD_TYPES.error:
        data = [payload.message]
        break
      default:
        throw new Error('No thread type ', type)
    }
    newUIThreadSection = {
      type,
      id: v4(),
      data,
    }
    uiThread.push(newUIThreadSection)
  }
  return uiThread
}

function getUIThreadWithNewPromptGroup({ state, prompts, groupId }) {
  const uiThread = [...state.uiThread]
  const withOtherPromptGroupsHidden = uiThread.map((group) => {
    if (group.type !== UI_THREAD_TYPES.prompts || group.hasSelectedPrompt) return group
    return {
      ...group,
      status: PROMPT_STATUS.hidden,
    }
  })
  const newThreadSection = {
    type: UI_THREAD_TYPES.prompts,
    id: groupId,
    data: prompts,
  }
  withOtherPromptGroupsHidden.push(newThreadSection)
  return withOtherPromptGroupsHidden
}

export {
  getUIThreadCopyWithNewItem,
  getUIThreadWithNewPromptGroup,
}
