import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
// eslint-disable-next-line
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  TypingIndicator,
  ConversationHeader,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react'

import './Chatbot.css'
import { SEED_MESSAGES, SEND_MESSAGE } from '../../store/reducerActions/messages/send'
import { Provider, store } from '../../store'
import { CHATBOT_PROPS } from '../../constants'
import { configureAxiosInstance } from '../../store/configureNetworking'
import arrowImage from '../../assets/ArrowCurveUp@3x.png'

function ChatbotInner(props) {
  const {
    companyName,
    associateName,
    token,
    associateId,
    overrideUrl,
    imageUrl,
    image,
    savedData,
    storeConversation,
  } = props

  const [globalState, dispatch] = store()
  const {
    messages,
    sendingMessage,
    threadId,
  } = globalState

  const [expandChat, setExpandChat] = useState(true)
  const [typing, setTyping] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    configureAxiosInstance({ token, overrideUrl })
  }, [overrideUrl, token])

  useEffect(() => {
    setTyping(sendingMessage)
  }, [sendingMessage])

  useEffect(() => {
    if (storeConversation) storeConversation({ messages, threadId })
  }, [messages])

  useEffect(() => {
    const conversation = savedData && savedData.messages
    const thread = savedData && savedData.threadId
    if (conversation && thread) {
      dispatch({
        type: SEED_MESSAGES,
        payload: {
          messages: conversation,
          threadId: thread,
        },
      })
    }
  }, [savedData])

  const onSendMessage = () => {
    const messageObj = {
      id: v4(),
      message,
      sentTime: '',
      sender: CHATBOT_PROPS.user,
      direction: CHATBOT_PROPS.direction.outgoing,
    }
    setMessage('')
    dispatch({
      type: SEND_MESSAGE,
      payload: {
        message: messageObj,
        displayMessages: [...messages],
        associateId,
      },
    })
  }

  const handleKeypress = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      onSendMessage()
    }
  }

  const onChangeMessage = (e) => {
    const { value } = e.target
    setMessage(value)
  }

  const minimizeChat = () => setExpandChat(false)
  const doExpandChat = () => setExpandChat(true)
  const getCollapsedClass = () => (expandChat ? '' : 'Chatbot-collapsed')
  // const getSizingOverrideClass = () => (window.devicePixelRatio > 2 && screen.width > 1200 ? 'overrideSize' : '')

  return (
    <div className={`Chatbot-container sizeOverride ${expandChat ? '' : 'Chatbot-collapsed-outer'}`}>
      <MainContainer className={`Chatbot-internalContainer override ${expandChat ? '' : 'Chatbot-collapsed-outer'}`}>
        <ChatContainer>
          <ConversationHeader className="Chatbot-header">
            <ConversationHeader.Actions className="Chatbot-chatActions">
              <Avatar src={image || imageUrl} name={companyName} size="sm" className="Chatbot-headerAvatar sizeOverride" />
              <div className="Chatbot-onlineIndicator sizeOverride">
                <div className="Chatbot-onlineIndicator-inner sizeOverride">
                  <div className="Chatbot-onlineIndicator-dot sizeOverride" />
                </div>
              </div>
              <ConversationHeader.Content className="Chatbot-headerTextGroup">
                <p className="Chatbot-headerName sizeOverride">{associateName || companyName}</p>
                <p className="Chatbot-onlineIndicatorText sizeOverride">online</p>
              </ConversationHeader.Content>
              {/* Hide this for now */}
              {/* <ConversationHeader.Content>
                {expandChat
                  ? <span onClick={minimizeChat} className='Chatbot-minimize' role='img'>➖</span>
                  : <span onClick={doExpandChat} className='Chatbot-minimize' role='img'>➕</span>
                }
              </ConversationHeader.Content> */}
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            className={`Chatbot-messageList ${getCollapsedClass()}`}
            typingIndicator={typing && (
              <TypingIndicator className="Chatbot-typingIndicator override" content={`${associateName || companyName} is generating an answer`} />
            )}
          >
            {messages.map((m) => {
              if (m.sender === CHATBOT_PROPS.user) {
                return (
                  <Message
                    key={m.id}
                    model={m}
                    className="Chatbot-message Chatbot-message-outgoing sizeOverride"
                  />
                )
              }
              return (
                <Message key={message.id} model={message} className="Chatbot-message Chatbot-message-incoming sizeOverride">
                  <Message.TextContent text={message.message} className="Chatbot-message-textContent" />
                </Message>
              )
            })}
          </MessageList>
        </ChatContainer>
      </MainContainer>
      <div className="Chatbot-messageInput">
        <div className="Chatbot-messageInput-inner">
          <input
            type="text"
            className="Chatobt-messageEditor sizeOverride"
            onKeyUp={handleKeypress}
            onChange={onChangeMessage}
            contentEditable="true"
            placeholder="Ask a question..."
            value={message}
          />
          {/* eslint-disable-next-line */}
          <button className="Chatbot-sendButton" onClick={onSendMessage}>
            {/* eslint-disable-next-line */}
            <img src={arrowImage} className="Chatbot-sendArrow" />
          </button>
        </div>
      </div>
    </div>
  )
}
/*
TODO: remove this when I'm sure the message input is working as I want
 <div className='Chatbot-messageInput'>
        <div className="cs-message-input__content-editor-wrapper">
          <div className='scrollbar-container cs-message-input__content-editor-container ps'>
            <div className='cs-message-input__content-editor' contentEditable='true' data-placeholder='Ask a question...'></div>
          </div>
        </div>
        <div className='cs-message-input__tools'>
          <button className='cs-button cs-button--send'>
            <img src={arrowImage} />
          </button>
        </div>
      </div>
*/

ChatbotInner.propTypes = {
  companyName: PropTypes.string.isRequired,
  associateName: PropTypes.string,
  overrideUrl: PropTypes.string,
  token: PropTypes.string,
  associateId: PropTypes.string,
  imageUrl: PropTypes.string,
  image: PropTypes.node,
  savedData: PropTypes.shape({
    messages: PropTypes.shape([]),
    threadId: PropTypes.string,
  }),
  storeConversation: PropTypes.func,
}

ChatbotInner.defaultProps = {
  overrideUrl: '',
  token: '',
  associateId: '',
  imageUrl: '',
  image: null,
  associateName: '',
  savedData: null,
  storeConversation: null,
}

function Chatbot(props) {
  return (
    <Provider>
      <ChatbotInner {...props} />
    </Provider>
  )
}

// TODO define these separately so that they propogate correctly when using the package
Chatbot.propTypes = {
  ...ChatbotInner.propTypes,
}

export default Chatbot
