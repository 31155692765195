import React from 'react'
import styled from 'styled-components'

import Header from './Header'
import Content from './Content'

function Body() {
  return (
    <main>
      <Header />
      <Content />
    </main>
  )
}

export default Body
