import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { v4 } from 'uuid'

import { CHATBOT_PROPS } from '../../constants'

const Wrapper = styled.div`
  padding: 10px;
  &:first-child {
    margin-top: 20px;
  }
`
const Text = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;

  font-family: ${({ theme }) => theme.fontFamily};
`
const Container = styled.div`
  margin-bottom: 20px;
`
const IncomingMessageWrapper = styled(Wrapper)`
  width: 100%;
  text-align: left;
  border-left: 1px solid ${({ theme }) => theme.inputBorderColor};
`
const IncomingMessage = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  margin-right: 20%;
`
const OutgoingMessageWrapper = styled(Wrapper)`
  border-right: 1px solid ${({ theme }) => theme.focusColor};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`
const OutgoingMessage = styled(Text)`
  font-weight: 500;
  width: 100%;
  margin-left: 20%;
  text-align: right;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
`

function MessageThread({ messages }) {
  const toRender = useMemo(() => {
    const thing = [...messages].map((message) => {
      if (message.direction === CHATBOT_PROPS.direction.incoming) {
        const split = message.message.split('\n')
        const splitMessages = split.map((splitMessage) => ({
          id: v4(),
          content: splitMessage,
        }))
        return {
          id: message.id,
          messages: splitMessages,
          direction: message.direction,
        }
      }
      return message
    })
    return thing
  }, [messages])
  return (
    <Container>
      { toRender.map((m) => {
        if (m.direction === CHATBOT_PROPS.direction.incoming) {
          return (
            <IncomingMessageWrapper key={m.id}>
              { m.messages.map((sm) => {
                if (sm.content === '') {
                  return <br key={sm.id} />
                }
                return <IncomingMessage key={sm.id}>{sm.content}</IncomingMessage>
              })}
            </IncomingMessageWrapper>
          )
        }
        return (
          <OutgoingMessageWrapper key={m.id}>
            <OutgoingMessage>{m.message}</OutgoingMessage>
          </OutgoingMessageWrapper>
        )
      })}
    </Container>
  )
}

MessageThread.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    sender: PropTypes.string,
    direction: PropTypes.string,
  })).isRequired,
}

export default MessageThread
