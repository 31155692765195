import axios from 'axios'
import _ from 'lodash'
import { v4 } from 'uuid'

import { ADD_OR_REMOVE_MESSAGE, CHATBOT_PROPS, UI_THREAD_TYPES } from '../../../constants'
import { axiosInstance } from '../../configureNetworking'
import { getUIThreadCopyWithNewItem } from '../helpers/uiThreadHelpers'

const SEND_MESSAGE = 'SEND_MESSAGE'
const SEND_MESSAGE__STARTED = 'SEND_MESSAGE__STARTED'
const SEND_MESSAGE__FINISHED = 'SEND_MESSAGE__FINISHED'
const SEND_MESSAGE__FAILED = 'SEND_MESSAGE__FAILED'
const SEED_MESSAGES = 'SEED_MESSAGES'

const DEFAULT_ERROR_MESSAGE = 'I\'m sorry. I ran into an issue processing this question. Please try again.'

function convertMessagesChatBotToServer(chatMessages) {
  return chatMessages.reduce((accumulator, cm) => {
    if (cm.type === ADD_OR_REMOVE_MESSAGE) { return accumulator }
    accumulator.push({
      ...cm,
      content: cm.message,
      role: cm.sender === CHATBOT_PROPS.user ? CHATBOT_PROPS.user : CHATBOT_PROPS.assistant,
      sent: cm.sender === CHATBOT_PROPS.me,
    })
    return accumulator
  }, [])
}

function convertAIAnswersToChatbotMessages(messages) {
  const getObj = (message) => ({
    id: v4(),
    message,
    sender: CHATBOT_PROPS.assistant,
    direction: CHATBOT_PROPS.direction.incoming,
  })
  if (messages.length < 1) {
    return [getObj(DEFAULT_ERROR_MESSAGE)]
  }
  return messages.map((message) => getObj(message.content))
}

const sendMessagesActions = {
  sync: {
    [SEED_MESSAGES]: (state, { payload }) => {
      return {
        ...state,
        messages: payload.messages,
        threadId: payload.threadId,
      }
    },
    [SEND_MESSAGE__STARTED]: (state, { payload }) => {
      return {
        ...state,
        sendingMessage: true,
        sendingMessageId: payload.message.id,
        messages: [
          ...state.messages,
          { ...payload.message },
        ],
        uiThread: getUIThreadCopyWithNewItem({ state, payload, type: UI_THREAD_TYPES.messages }),
      }
    },
    [SEND_MESSAGE__FINISHED]: (state, { payload }) => {
      return {
        ...state,
        messages: [
          ...state.messages,
          ...payload.messages,
        ],
        ownersManualChatId: payload.chatId,
        sendingMessage: false,
        threadId: payload.threadId,
        uiThread: getUIThreadCopyWithNewItem({ state, payload, type: UI_THREAD_TYPES.messages }),
      }
    },
    [SEND_MESSAGE__FAILED]: (state, { payload }) => ({
      ...state,
      sendingMessage: false,
      errorSendingMessage: payload.errorMessage,
      messages: [
        ...state.messages,
        { ...payload.message },
      ],
      uiThread: getUIThreadCopyWithNewItem({ state, payload, type: UI_THREAD_TYPES.error }),
    }),
  },
  async: {
    [SEND_MESSAGE]: ({ dispatch, getState }) => async ({ payload }) => {
      try {
        dispatch({ type: SEND_MESSAGE__STARTED, payload })

        const { threadId, sendingMessageId } = getState()
        // TODO: Check if this is actually doing anything
        if (sendingMessageId === payload.message.id) { return }
        const serverPayload = {
          message: _.get(payload, 'message.message', ''),
          assistantId: _.get(payload, 'associateId', ''),
          threadId,
        }

        const response = await axiosInstance.post('/api/messages', serverPayload)

        let aiAnswers = _.get(response, 'data.messages', [])
        const newThreadId = _.get(response, 'data.threadId', '')

        if (aiAnswers.length < 1) {
          aiAnswers = convertAIAnswersToChatbotMessages([])
        }

        const convertedMessages = convertAIAnswersToChatbotMessages(aiAnswers)

        dispatch({
          type: SEND_MESSAGE__FINISHED,
          payload: {
            messages: convertedMessages,
            threadId: newThreadId,
          },
        })
      } catch (error) {
        // TODO: Figure out what this will look like
        console.log('error sending/receiving message ', error)
        dispatch({
          type: SEND_MESSAGE__FAILED,
          payload: {
            errorMessage: error.message,
            message: _.first(convertAIAnswersToChatbotMessages([])),
          },
        })
      }
    },
  },
}

export {
  SEND_MESSAGE,
  SEED_MESSAGES,
}

export default sendMessagesActions
