import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import _ from 'lodash'

import { Provider, store } from '../../store'
import Chatbar from './Chatbar'
import Panel from './Panel'
import { configureAxiosInstance } from '../../store/configureNetworking'
import { FETCH_PROMPTS } from '../../store/reducerActions/prompts'
import { configureNavigationListener, log } from '../../store/configureNavigationListener'
import { SEED_GLOBAL_STATE } from '../../store/reducerActions/globalState'
import { AppContext } from '../../contexts/AppContext'
/**
 * Slack colors:
 * main background dark gray: 1B1D21
 * chat input gray: 222529
 * plus button lighter gray: 2E3135
 * chat border whte/gray: 808385
 *
 */
const themes = {
  slack: {
    isDark: true,
    backgroundColor: '#1B1D21',
    inputBackgroundColor: '#222529',
    buttonBackground: '#2E3135',
    inputBorderColor: '#808385',
    buttonIconColor: '#ADAEAF',
    textColor: '#D1D2D3',
  },
  darkMode: {
    isDark: true,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    buttonBackground: 'transparent',
    inputBorderColor: 'rgb(73, 73, 73)',
    inputBackgroundColor: 'transparent',
    textColor: 'rgb(255, 255, 255)',
    // == new ==
    focusColor: 'rgb(234, 166, 14)',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
  hearst: {
    isDark: false,
    backgroundColor: 'rgba(224, 224, 224, 0.72)',
    focusColor: '#D2222E', // 'rgb(210,34,46)',
    inputBackgroundColor: 'transparent',
    inputBorderColor: 'rgb(184, 184, 184)',
    buttonBackgroundColor: 'rgb(255, 255, 255)', // white
    textColor: 'rgb(0, 0, 0)',
    border: '2px solid rgba(214, 214, 214, 0.7)',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    alwaysCollapsed: true,
  },
}

const Container = styled.div`
  color: ${({ theme }) => theme.textColor};
  // Override or reset styles from external stylesheets with 'initial' value
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`

function ChatbarAndPanel(props) {
  const [globalState, dispatch] = store()
  const { uiThread, fetchingPrompts } = globalState
  const {
    isPanel,
    associateId,
    token,
    overrideUrl,
    cacheDataExternally,
  } = props

  // Nice. This will fire before render whereas useEffect fires after.
  // We want to ensure this is configured ASAP for any other network calls that happen onRender
  useMemo(() => {
    configureAxiosInstance({ token, overrideUrl })
    configureNavigationListener()
  }, [])

  const currentURL = window.location.href
  const lastURL = localStorage.getItem('CURRENT_URL')

  useEffect(() => {
    const thread = _.get(globalState, 'uiThread', [])
    console.log('fetchingPrompts in effect', fetchingPrompts)
    if (lastURL !== currentURL || thread.length < 1) {
      dispatch({ type: FETCH_PROMPTS, payload: { url: currentURL } })
    }
    return () => {
      localStorage.setItem('CURRENT_URL', currentURL)
    }
  }, [lastURL, currentURL])
  console.log('fetchingPrompts in normal func ', fetchingPrompts)

  useEffect(() => {
    // ==============
    // forget what this is for right now, it's saved in the `beforeunload` event
    // I think saving to localStorage will already have the effect of keeping track of the
    // data by domain, though
    // const lastClosed = localStorage.getItem('LAST_CLOSED')
    // ==============
    const cachedStateString = localStorage.getItem('CACHED_STATE')
    if (cachedStateString) {
      const cachedState = JSON.parse(cachedStateString)
      dispatch({
        type: SEED_GLOBAL_STATE,
        payload: cachedState,
      })
    }
  }, [])

  useEffect(() => {
    if (!uiThread || uiThread.length < 1) return
    localStorage.setItem('CACHED_STATE', JSON.stringify(globalState))
    if (cacheDataExternally) cacheDataExternally()
  }, [uiThread])

  let body
  if (isPanel) {
    body = <Panel associateId={associateId} />
  } else {
    body = <Chatbar />
  }

  return (
    <Container>
      {body}
    </Container>
  )
}
function ChatWrapper(props) {
  const { isDemo, isDark } = props
  const contextValue = useMemo(() => ({ isDemo, isDark }), [isDemo, isDark])
  return (
    <Provider>
      <ThemeProvider theme={isDark ? themes.darkMode : themes.hearst}>
        <AppContext.Provider value={contextValue}>
          <ChatbarAndPanel {...props} />
        </AppContext.Provider>
      </ThemeProvider>
    </Provider>
  )
}

ChatbarAndPanel.propTypes = {
  isPanel: PropTypes.bool.isRequired,
  associateId: PropTypes.string.isRequired,
  token: PropTypes.string,
  overrideUrl: PropTypes.string,
  cacheDataExternally: PropTypes.func,
}

ChatbarAndPanel.defaultProps = {
  overrideUrl: '',
  cacheDataExternally: null,
  token: '',
}

ChatWrapper.propTypes = {
  isDemo: PropTypes.bool,
  isDark: PropTypes.bool,
}

ChatWrapper.defaultProps = {
  isDemo: false,
  isDark: true,
}

export default ChatWrapper
