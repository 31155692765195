const CHATBOT_PROPS = {
  direction: {
    outgoing: 'outgoing',
    incoming: 'incoming',
  },
  me: 'Me',
  user: 'user',
  assistant: 'assistant',
}

const ORIENTATION = {
  chatbot: 'CHATBOT',
  panel: 'PANEL',
  bottomBar: 'BOTTOM_BAR',
}

const ADD_OR_REMOVE_MESSAGE = 'addOrRemove'

const UI_THREAD_TYPES = {
  messages: 'messages',
  prompts: 'prompts',
  fetchingPrompts: 'fetchingPrompts',
  sendingMessage: 'sendingMessage',
  error: 'error',
}

// Applies to both individual prompts and groupings of prompts
const PROMPT_STATUS = {
  selected: 'selected',
  hidden: 'hidden',
}

export {
  CHATBOT_PROPS,
  ADD_OR_REMOVE_MESSAGE,
  ORIENTATION,
  UI_THREAD_TYPES,
  PROMPT_STATUS,
}
