import axios from 'axios'

// eslint-disable-next-line import/no-mutable-exports
let axiosInstance

function configureAxiosInstance({ token, overrideUrl }) {
  let baseURL
  // TODO: this system doesn't work when using this as an npm package
  if (overrideUrl) {
    baseURL = overrideUrl
  } else if (process.env.HOST_URL) {
    baseURL = process.env.HOST_URL
  } else {
    baseURL = process.env.REACT_APP_HOST_URL
  }
  let base = {
    baseURL,
    params: {
      isDemoExtension: true,
    },
  }
  if (token) {
    base = {
      ...base,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }
  axiosInstance = axios.create(base)
}

export {
  axiosInstance,
  configureAxiosInstance,
}
