import axios from 'axios'

import _ from 'lodash'
import { v4 } from 'uuid'
import { axiosInstance } from '../configureNetworking'
import { PROMPT_STATUS, UI_THREAD_TYPES } from '../../constants'
import { getUIThreadWithNewPromptGroup } from './helpers/uiThreadHelpers'

const FETCH_PROMPTS = 'FETCH_PROMPTS'
const FETCH_PROMPTS__STARTED = 'FETCH_PROMPTS__STARTED'
const FETCH_PROMPTS__FAILED = 'FETCH_PROMPTS__FAILED'
const FETCH_PROMPTS__FINISHED = 'FETCH_PROMPTS__FINISHED'
const SELECT_PROMPT = 'SELECT_PROMPT'

const promptActions = {
  sync: {
    [SELECT_PROMPT]: (state, { payload }) => {
      const { groupId, id } = payload
      const selectedPrompt = {
        ...payload,
        selected: true,
        status: PROMPT_STATUS.selected,
      }

      // Create a new uiThread object with updated properties
      const uiThread = [...state.uiThread]
      const promptGroupIndex = uiThread.findIndex((threadItem) => threadItem.id === groupId)
      const promptGroup = { ...uiThread[promptGroupIndex] }
      promptGroup.hasSelectedPrompt = true
      const selectedIdx = promptGroup.data.findIndex((prompt) => prompt.id === id)
      const promptGroupData = promptGroup.data.map((pmpt, idx) => {
        if (idx === selectedIdx) {
          return selectedPrompt
        }
        return {
          ...pmpt,
          status: PROMPT_STATUS.hidden,
        }
      })
      promptGroup.data = promptGroupData
      const newUIThread = [
        ...uiThread.slice(0, promptGroupIndex),
        promptGroup,
        ...uiThread.slice(promptGroupIndex + 1),
      ]

      return {
        ...state,
        selectedPrompt,
        uiThread: newUIThread,
      }
    },
    [FETCH_PROMPTS__STARTED]: (state, { payload }) => {
      return {
        ...state,
        fetchingPrompts: true,
        fetchingPromptsUrl: payload.url,
        fetchingPromptsError: '',
      }
    },
    [FETCH_PROMPTS__FAILED]: (state, { payload }) => {
      return {
        ...state,
        fetchingPrompts: false,
        fetchingPromptsError: payload,
      }
    },
    [FETCH_PROMPTS__FINISHED]: (state, { payload }) => {
      const id = v4()
      console.log('prompts returned ', payload)
      const prompts = payload && Array.isArray(payload) ? payload.map((prompt) => ({
        body: prompt,
        id: v4(),
        groupId: id,
      })) : []
      return {
        ...state,
        fetchingPrompts: false,
        fetchingPromptsError: '',
        prompts,
        uiThread: getUIThreadWithNewPromptGroup({ state, prompts, groupId: id }),
      }
    },
  },
  async: {
    [FETCH_PROMPTS]: ({ dispatch, getState }) => async ({ payload }) => {
      try {
        const { url } = payload
        const { fetchingPromptsUrl, fetchingPrompts } = getState()
        console.log('state fetching? ', fetchingPrompts)
        // TODO: This isn't really working, multiple fetches are finishing. Right now we're hiding old prompts, though
        // so the old groups will update to `status: 'hidden'` but they should not be in the uiThread at all. That or the
        // next request going out should get canceled in dev mode. Still figuring out how to do that
        if (url === fetchingPromptsUrl || fetchingPrompts) {
          return
        }
        console.log('FETCH_PROMPTS')
        dispatch({ type: FETCH_PROMPTS__STARTED, payload })
        // const response = await axiosInstance.get(`/api/chatPrompts?url=${url}`)
        const response = await axiosInstance.get(`/api/chatPrompts/generate?url=${url}`)
        const prompts = _.get(response, 'data.prompts', [])
        dispatch({ type: FETCH_PROMPTS__FINISHED, payload: prompts })
      } catch (err) {
        console.log('error fetching prompts ', err)
        dispatch({ type: FETCH_PROMPTS__FAILED, payload: err.message })
      }
    },
  },
}

export {
  SELECT_PROMPT,
  FETCH_PROMPTS,
}

export default promptActions
