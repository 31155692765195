import React, { useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import logo from './logo.svg'
import './App.css'

import { AppContext } from './contexts/AppContext'
import Layout from './recreated/Layout'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
const ModalText = styled.div`
  font-size: 0.70028rem;
  line-height: 1.2;
  color: #6F6F6F;
  font-family: GraphikBold,GraphikBold-upcase-roboto,GraphikBold-upcase-local,Helvetica,Arial,Sans-serif;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-top: 0.625rem;
  text-transform: uppercase;
  text-align: left;
`
function App() {
  const [showModal, setShowModal] = useState(false)
  const contextValue = useMemo(() => ({
    onClickLink: () => {
      setShowModal(true)
    },
  }), [])
  return (
    <AppContext.Provider value={contextValue}>
      <div className="App">
        <Layout />
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box sx={style}>
            <ModalText>
              This is just a playground website to demonstrate the use of Keys.
              <br />
              <br />
              All links are currently inactive
            </ModalText>
          </Box>
        </Modal>
      </div>
    </AppContext.Provider>
  )
}

export default App
