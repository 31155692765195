import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { store } from '../../store'
import Prompts from './Prompts'
import { Break } from './styled/Break'
import { Input, InputWrapper } from './styled/Input'

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 110px;
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.backgroundColor};
`
const ActionsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`
// TODO: make a styled button component. Reused in Prompts.js
const Action = styled.button`
  padding: 5px;
  line-height: 1;
  height: fit-content;
  border-radius: 10px;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.inputBackgroundColor};
  border: ${({ theme }) => `1px solid ${theme.inputBorderColor}`};
`
const PromptsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
`
const PromptsLabel = styled.p`
  margin: 0;
  margin-bottom: 8px;
`

function Chatbar({ sendMessage }) {
  const [globalState] = store()
  const { prompts } = globalState

  return (
    <Container>
      <InputWrapper>
        <Input
          placeholder="What's on your mind?"
        />
        <Break />
        <ActionsWrapper>
          <Action>Schedule Test Drive</Action>
        </ActionsWrapper>
      </InputWrapper>
      <PromptsWrapper>
        <PromptsLabel>Or choose an FAQ</PromptsLabel>
        <Prompts prompts={prompts} onSelectPrompt={sendMessage} />
      </PromptsWrapper>
    </Container>
  )
}

Chatbar.propTypes = {
  sendMessage: PropTypes.func.isRequired,
}

export default Chatbar
